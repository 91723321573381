import React, { useState, useEffect } from 'react';

const Home = () => {
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString('tr-TR'));
    const wallpaperUrl = [
        {
            "class": "bg-wallpaper1",
            "url": "https://unsplash.com/photos/body-of-water-surrounded-by-pine-trees-during-daytime-ESkw2ayO2As",
            "selected": false
        },
        {
            "class": "bg-wallpaper2",
            "url": "https://unsplash.com/photos/low-angle-photography-of-trees-at-daytime-4rDCa5hBlCs",
            "selected": true
        },
    ]

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString('tr-TR'));
        }, 1000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(timer);
    }, []);

    var selectedWallPaperClass = wallpaperUrl.find(wallpaper => wallpaper.selected).class;
    var selectedWallPaperUrl = wallpaperUrl.find(wallpaper => wallpaper.selected).url;

    return (
        <div className={`w-screen h-screen flex items-center justify-center scrollbar-hide bg-cover bg-no-repeat ${selectedWallPaperClass}`}>
            <div className=" p-8 rounded-lg backdrop-blur-sm bg-neutral-800/30">
                <h1 className="text-8xl font-bold text-center">{currentTime}</h1>
            </div>
            <div className="absolute bottom-0 left-0 p-4 text-neutral-300">
                <a href={selectedWallPaperUrl} target="_blank" rel="noopener noreferrer" className='text-sm bg-neutral-950 text-neutral-50'>Author</a>
            </div>
        </div>

    );
};

export default Home;