import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line no-unused-vars
import customCss from '../custom-module.css';

const YOUTUBE_PLAYLIST_ID = 'PLOaHBFpyEjq5FDD8ErGLcGqyQvxZm6vAc'; // Replace with your YouTube Playlist ID
const API_KEY = 'AIzaSyAJtiqjIXU4LkTie486RBbe6IeMxqKCaL4'; // Replace with your YouTube API Key

const Music = () => {
    const playerRef = useRef(null); // Ref to reference the YouTube player element
    const playerInstance = useRef(null); // Ref to hold the player instance (instead of using state)
    const [playlistItems, setPlaylistItems] = useState([]);
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(9);
    const [progress, setProgress] = useState(0);

    // Update progress bar function
    const updateProgressBar = () => {
        if (playerInstance.current && playerInstance.current.getDuration) {
            const currentTime = playerInstance.current.getCurrentTime();
            const duration = playerInstance.current.getDuration();
            if (duration && currentTime >= 0) {
                setProgress((currentTime / duration) * 100);
            }
        }
    };

    // Player ready event handler
    const onPlayerReady = () => {
        // console.log('Player is ready:', playerInstance.current); // Now `playerInstance.current` should be available
        setInterval(updateProgressBar, 100); // Call progress bar every 100ms after player is ready
    };

    // Player error handler
    const onPlayerError = (event) => {
        console.error('Player error:', event);
    };

    // Player state change event handler
    const onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING) {
            setCurrentVideoId(event.target.playerInfo.videoData.video_id);
            setIsPlaying(true);
            updateProgressBar();
        } else {
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        // Load YouTube Iframe API
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            playerInstance.current = new window.YT.Player(playerRef.current, {
                width: '0', // Hide video width
                height: '0', // Hide video height
                playerVars: {
                    listType: 'playlist',
                    list: YOUTUBE_PLAYLIST_ID,
                    autoplay: 0,
                    controls: 0, // Disable default controls
                },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange,
                    onError: onPlayerError,
                },
            });
            // console.log('Setting player', playerInstance.current); // Debugging log to verify the player instance is set
        };

        // Fetch playlist items from YouTube API
        const fetchPlaylistItems = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=25&playlistId=${YOUTUBE_PLAYLIST_ID}&key=${API_KEY}`
                );
                setPlaylistItems(response.data.items);
            } catch (error) {
                console.error('Error fetching playlist items', error);
            }
        };

        fetchPlaylistItems();

        // Clean up the player when the component unmounts
        return () => {
            if (playerInstance.current) {
                playerInstance.current.destroy(); // Correctly destroy the player instance
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Only run once when the component mounts

    // Player controls
    const playAudio = () => {
        if (playerInstance.current) {
            setVolumeToPlayer();
            playerInstance.current.playVideo();
        }
    };

    const pauseAudio = () => {
        if (playerInstance.current) {
            playerInstance.current.pauseVideo();
        }
    };

    const nextAudio = () => {
        if (playerInstance.current) {
            playerInstance.current.nextVideo();
        }
    };

    const prevAudio = () => {
        if (playerInstance.current) {
            playerInstance.current.previousVideo();
        }
    };

    const setVolumeToPlayer = () => {
        if (playerInstance.current) {
            playerInstance.current.setVolume(volume);
        }
    };

    // Render playlist items and highlight current song
    return (
        <div>
            {/* The YouTube iframe will be hidden */}
            <div id="player" ref={playerRef} style={{ display: 'none' }}></div>

            {/* Playlist Items */}
            <div className="playlist">
                <div className='image-container flex justify-center align-middle'>
                    {currentVideoId && (
                        <img
                            src={`https://img.youtube.com/vi/${currentVideoId}/mqdefault.jpg`}
                            alt='Thumbnail'
                            className='size-32'
                        />
                    )}
                </div>
                {playlistItems.map((item) => (
                    item.snippet.resourceId.videoId === currentVideoId &&
                    <span key={item.snippet.resourceId.videoId}>{item.snippet.title.substring(0, 20)}...</span>
                ))}
            </div>

            <div>
                <progress className='musicProgress' value={progress} max="100"></progress> {/* Progress bar */}
            </div>

            {/* Custom controls */}
            <div className="controls flex flex-row gap-x-2 justify-center">
                <button onClick={prevAudio} className='text-neutral-900'><FontAwesomeIcon icon={faBackward} className='size-6 pt-2' /></button>
                {isPlaying
                    ? <button onClick={pauseAudio} className='text-neutral-900'><FontAwesomeIcon icon={faPause} className='size-6 pt-2' /></button>
                    : <button onClick={playAudio} className='text-neutral-900'><FontAwesomeIcon icon={faPlay} className='size-6 pt-2' /></button>
                }
                <button onClick={nextAudio} className='text-neutral-900'><FontAwesomeIcon icon={faForward} className='size-6 pt-2' /></button>
                <input
                    className='playerSlider'
                    type="range"
                    min={0}
                    max={100}
                    step={1}
                    value={volume}
                    onChange={event => {
                        setVolume(event.target.valueAsNumber);
                        setVolumeToPlayer();
                    }}
                />
            </div>
            <span className='text-xs'>
                All songs came from <a href="https://music.youtube.com/playlist?list=PLOaHBFpyEjq5FDD8ErGLcGqyQvxZm6vAc&si=LCKDC4ej9EIdxgHp" className='underline'>there</a><br /> and this one is public playlist <br />you can contribute if you want.
            </span>
        </div>
    );
};

export default Music;
