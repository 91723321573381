import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Music from '../tools/pages/music';

const SwissKnifeButton = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuFullyVisible, setMenuIsFullyVisible] = useState(false);
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);
    const [isPlayerFullyVisible, setPlayerIsFullyVisible] = useState(false);

    const toggleVisibility = event => {
        if (event.nativeEvent.layerY < 50) {

            if (isMenuVisible) {
                setIsMenuVisible(false);
                setTimeout(() => setMenuIsFullyVisible(false), 500); // Hide after fade-out
            } else {
                setMenuIsFullyVisible(true);
                setTimeout(() => setIsMenuVisible(true), 10); // Show after adding to the DOM
            }
        }
        else {
            if (isPlayerVisible) {
                setIsPlayerVisible(false);
                setTimeout(() => setPlayerIsFullyVisible(false), 500); // Hide after fade-out
            } else {
                setIsPlayerVisible(true);
                setTimeout(() => setPlayerIsFullyVisible(true), 10); // Show after adding to the DOM
            }
        }
    };
    return <>

        <div className={`fixed 
                         bottom-20 
                         right-5 
                        bg-swissknifebtn-light 
                        text-swissknifebtn-dark 
                        p-2 
                        rounded-lg 
                        overflow-hidden 
                        cursor-pointer
                        transition-opacity duration-500 ease-in-out
                         ${isMenuVisible ? 'opacity-100' : 'opacity-0'} 
                         ${isMenuFullyVisible ? 'block' : 'hidden'}`}>
            <nav className='p-1'>
                <ul>
                    <li className='pb-1'>
                        <Link to="/">Home</Link>
                    </li>
                    <li className='pb-1'>
                        <Link to="/jsoneditor">JsonEditor</Link>
                    </li>
                    <li className='pb-1'>
                        <Link to="/embeds/excalidraw">Excalidraw</Link>
                    </li>
                </ul>
            </nav>
        </div>

        <div className={`fixed 
                         bottom-5 
                         right-20
                        bg-swissknifebtn-light 
                        text-swissknifebtn-dark 
                        p-2 
                        rounded-lg 
                        overflow-hidden 
                        transition-opacity duration-500 ease-in-out
                         ${isPlayerVisible ? 'opacity-100' : 'opacity-0'} 
                         ${isPlayerFullyVisible ? 'block' : 'hidden'}`}>
            <Music />
        </div>

        <div className="fixed bottom-0 right-0 size-24" onClick={toggleVisibility}>
            <div className="bg-swiss-knife-button bg-cover bg-center h-24 w-full cursor-pointer"></div>
        </div>
    </>;
};

export default SwissKnifeButton;